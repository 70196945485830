
.abundant-health-button {
  transition: visibility 0s, opacity 1s linear;
  display: inline-block;
  border: 1px solid #e85764;
  background-color: #de3a47;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 16px;
  &:hover {
    border: 1px solid #de3a47 !important;
    background-color: #c63641 !important;
    color: #f6f6f6;
  }
}

.product_productprice,
.fade-in,
// For product type without options
#v65-product-parent #v65-productdetail-action-wrapper > table, 
// Input Quantiy For product type with options
#options_table ~ table > tbody > tr,
[name="btnaddtocart"],
.product_discountprice {
  transition: visibility 0s, opacity 1s linear;
}

body:not(.access-granted):not(.access-restricted) {
  .product_productprice,
  .fade-in,
  #v65-product-parent #v65-productdetail-action-wrapper > table,
  [name="btnaddtocart"],
  #options_table ~ table > tbody > tr
  {
    visibility: hidden;
    opacity: 0;
  }
}

body.access-restricted {
  .product_productprice.restricted {
    font-size: 14px;
    color: #e74f5d;
    font-weight: 600;
  }

  #v65-product-parent.restricted {
    [name="btnaddtocart"],
    #v65-productdetail-action-wrapper > table,
    #options_table ~ table > tbody > tr
    {
      visibility: hidden;
      opacity: 0;
    }
    #options_table {
      pointer-events: none;
    }
    #v65-productdetail-action-wrapper {
      text-align: left;
    }
  }
  .product_discountprice {
    visibility: hidden;
    opacity: 1;
  }
}

.ReactModalPortal {
  @media (min-width: 720px) {
    .ReactModal__Content {
      max-width: 700px;
      width: 700px;
      margin: auto;
      position: absolute;
      top: calc(20% - 242px / 2);
      right: calc(50% - 700px / 2);
    }
  }

  @media (max-width: 415px) {
    .ReactModal__Content {
      min-height: 100vh;
    }
    .modal-body {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .modal-body {
    background-color: white;
    color:#4a4b51;
    padding:0 !important;
    .modal-header {
      display: flex;
      justify-content: space-between;
      padding: 11px 24px;
      border-bottom: 1px solid #8484842b;
      h2 {
        margin:0;
        line-height: 41px; 
      }
      .close-modal-button-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .close-modal-button {
        background-color: transparent;
        color: #4a4b51;
        font-size: 34px;
        border-width: 0;
      }
    }
    .modal-body-content {
      padding: 11px 24px;
      font-size: 16px;
    }
    .modal-footer {
      border-top: 1px solid #8484842b;
      padding: 11px 24px;
      text-align: right;
    }
  }
}